import React, { useState } from "react";
import { graphql } from "gatsby";
import { Switch } from "@headlessui/react";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Checkmark from "../images/global/checkmark.svg";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const [isMonthly, setIsMonthly] = useState(false);

  return (
    <Layout>
      <SearchEngineOptimization
        title="Client Pricing | Realsynch | Real Estate Automation"
        description="Choose a pricing plan that fits your business—whether you're a small real estate team looking for a starter solution, a growing firm, or a large realty company."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="bg-primary-900 pt-12 pb-20 md:mb-7 md:bg-white md:pt-12 md:pb-0">
        <div className="container">
          <header className="mx-auto mb-10 text-center md:mb-12 md:max-w-[720px]">
            <h1 className="text-white md:text-typography-heading">
              Time-Saving Integration Starts Here
            </h1>
            <p className="text-white/80 md:text-typography-body">
              We have pricing plans to support all levels—whether you’re a small
              real estate team looking for a starter solution, a growing firm,
              or a large realty company with many moving parts.
            </p>
          </header>

          <div className="mb-12 flex items-center justify-center space-x-3">
            <div className="text-sm font-extrabold uppercase text-white md:text-typography-heading">
              Annual
            </div>
            <Switch
              checked={isMonthly}
              onChange={setIsMonthly}
              className={`${isMonthly}
          relative inline-flex h-6 w-[44px] flex-shrink-0 cursor-pointer rounded-xl bg-gradient-to-br from-primary-400 to-primary-700`}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${
                  isMonthly ? "translate-x-[22px]" : "translate-x-0.5"
                }
            pointer-events-none inline-block h-5 w-5 translate-y-0.5 transform rounded-full bg-white shadow-5xl ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
            <div className="text-sm font-extrabold uppercase text-white md:text-typography-heading">
              Monthly
            </div>
          </div>

          <div className="grid gap-y-10 md:hidden md:grid-cols-1">
            <div className="rounded-3xl bg-white p-6">
              <div className="mb-4 inline-flex items-center justify-center rounded bg-primary-700/10 px-3 py-1 text-center font-bold text-primary-700">
                Starter
              </div>
              <div className="mb-6 flex items-center space-x-1">
                <div className="text-4xl font-bold text-typography-heading">
                  {isMonthly ? "$29.99" : "$23.99"}
                </div>
                <div className="font-body text-sm">/ month</div>
              </div>
              <div className="mb-5 h-px bg-gray-200"></div>
              <ul className="styled-list-checkmark mb-6 font-semibold">
                <li>1 Synch</li>
                <li>100 Tasks</li>
                <li>Access to CRMs</li>
              </ul>
              <ButtonSolid
                href={
                  isMonthly
                    ? "https://app.realsynch.com/register?plan=clientstarter&version=5"
                    : "https://app.realsynch.com/register?plan=annualclientstarter&version=5"
                }
                outboundLink={true}
                text="Choose Starter"
                className="w-full"
              />
            </div>

            <div className="rounded-3xl bg-white p-6">
              <div className="mb-4 inline-flex items-center justify-center rounded bg-primary-700/10 px-3 py-1 text-center font-bold text-primary-700">
                Grow
              </div>
              <div className="mb-6 flex items-center space-x-1">
                <div className="text-4xl font-bold text-typography-heading">
                  {isMonthly ? "$74.99" : "$59.99"}
                </div>
                <div className="font-body text-sm">/ month</div>
              </div>
              <div className="mb-5 h-px bg-gray-200"></div>
              <ul className="styled-list-checkmark mb-6 font-semibold">
                <li>Unlimited Synchs</li>
                <li>1,000 Tasks</li>
                <li>Access to CRMs</li>
                <li>Unlimited Users</li>
              </ul>
              <ButtonSolid
                href={
                  isMonthly
                    ? "https://app.realsynch.com/register?plan=grow&version=5"
                    : "https://app.realsynch.com/register?plan=annualgrow&version=5"
                }
                outboundLink={true}
                text="Choose Grow"
                className="w-full"
              />
            </div>

            <div className="rounded-3xl bg-white p-6">
              <div className="mb-4 inline-flex items-center justify-center rounded bg-primary-700/10 px-3 py-1 text-center font-bold text-primary-700">
                Platform
              </div>
              <div className="mb-6 flex items-center space-x-1">
                <div className="text-4xl font-bold text-typography-heading">
                  {isMonthly ? "$249.99" : "$199.99"}
                </div>
                <div className="font-body text-sm">/ month</div>
              </div>
              <div className="mb-5 h-px bg-gray-200"></div>
              <ul className="styled-list-checkmark mb-6 font-semibold">
                <li>Unlimited Synchs</li>
                <li>Unlimited Tasks</li>
                <li>Access to CRMs</li>
                <li>Unlimited Users</li>
                <li>Dedicated Support</li>
                <li>Analytical Reports</li>
              </ul>
              <ButtonSolid
                href={
                  isMonthly
                    ? "https://app.realsynch.com/register?plan=platform&version=5"
                    : "https://app.realsynch.com/register?plan=annualplatform&version=5"
                }
                outboundLink={true}
                text="Choose Platform"
                className="w-full"
              />
            </div>

            <div className="rounded-3xl bg-white p-6">
              <div className="mb-4 inline-flex items-center justify-center rounded bg-primary-700/10 px-3 py-1 text-center font-bold text-primary-700">
                Custom Services
              </div>
              <div className="mb-6 flex items-center space-x-1">
                <div className="text-4xl font-bold text-typography-heading">
                  {isMonthly ? "$250" : "$250"}
                </div>
                <div className="font-body text-sm">/ hour</div>
              </div>
              <div className="mb-5 h-px bg-gray-200"></div>
              <ul className="styled-list-checkmark mb-6 font-semibold">
                <li>Unlimited Synchs</li>
                <li>Unlimited Tasks</li>
                <li>Access to CRMs</li>
                <li>Unlimited Users</li>
                <li>Setup</li>
                <li>Consulting</li>
                <li>Analytical Reports</li>
              </ul>
              <ButtonSolid
                modal="modal-contact"
                text="Schedule a Consult"
                className="w-full"
              />
            </div>
          </div>

          <div className="hidden space-x-10 pl-7 pr-2 md:flex">
            <div className="min-w-[148px] lg:min-w-[208px]"></div>
            <div className="flex w-full md:grid md:grid-cols-4">
              <div className="px-2.5 pb-4 lg:px-5">
                <div className="mb-3.5 text-lg font-bold text-typography-heading">
                  Starter
                </div>
                <div className="mb-3 flex items-center space-x-1">
                  <div className="text-3xl font-bold text-typography-heading lg:text-4xl">
                    {isMonthly ? "$29.99" : "$23.99"}
                  </div>
                  <div className="font-body text-sm">/ mo</div>
                </div>

                <ButtonSolid
                  altStyle={3}
                  href={
                    isMonthly
                      ? "https://app.realsynch.com/register?plan=clientstarter&version=5"
                      : "https://app.realsynch.com/register?plan=annualclientstarter&version=5"
                  }
                  outboundLink={true}
                  text="Choose Starter"
                  className="w-full min-w-0"
                />
              </div>

              <div className="px-2.5 pb-4 lg:px-5">
                <div className="mb-3.5 text-lg font-bold text-typography-heading">
                  Grow
                </div>
                <div className="mb-3 flex items-center space-x-1">
                  <div className="text-3xl font-bold text-typography-heading lg:text-4xl">
                    {isMonthly ? "$74.99" : "$59.99"}
                  </div>
                  <div className="font-body text-sm">/ mo</div>
                </div>

                <ButtonSolid
                  altStyle={3}
                  href={
                    isMonthly
                      ? "https://app.realsynch.com/register?plan=grow&version=5"
                      : "https://app.realsynch.com/register?plan=annualgrow&version=5"
                  }
                  outboundLink={true}
                  text="Choose Grow"
                  className="w-full min-w-0"
                />
              </div>

              <div className="px-2.5 pb-4 lg:px-5">
                <div className="mb-3.5 text-lg font-bold text-typography-heading">
                  Platform
                </div>
                <div className="mb-3 flex items-center space-x-1">
                  <div className="text-3xl font-bold text-typography-heading lg:text-4xl">
                    {isMonthly ? "$249.99" : "$199.99"}
                  </div>
                  <div className="font-body text-sm">/ mo</div>
                </div>

                <ButtonSolid
                  altStyle={3}
                  href={
                    isMonthly
                      ? "https://app.realsynch.com/register?plan=platform&version=5"
                      : "https://app.realsynch.com/register?plan=annualplatform&version=5"
                  }
                  outboundLink={true}
                  text="Choose Platform"
                  className="w-full min-w-0"
                />
              </div>

              <div className="px-2.5 pb-4 lg:px-5">
                <div className="mb-3.5 text-lg font-bold text-typography-heading">
                  Custom Services
                </div>
                <div className="mb-3 flex items-center space-x-1">
                  <div className="text-3xl font-bold text-typography-heading lg:text-4xl">
                    {isMonthly ? "$250" : "$250"}
                  </div>
                  <div className="font-body text-sm">/ hr</div>
                </div>

                <ButtonSolid
                  altStyle={3}
                  modal="modal-contact"
                  text="Schedule a Consult"
                  className="w-full min-w-0"
                />
              </div>
            </div>
          </div>

          <div className="hidden space-x-10 rounded-3xl bg-primary-900 py-2 pl-7 pr-2 md:flex">
            <div className="min-w-[148px] lg:min-w-[208px]">
              <div className="flex h-[66px] items-center font-semibold text-white">
                Synchs
              </div>
              <div className="flex h-[66px] items-center font-semibold text-white">
                Tasks
              </div>
              <div className="flex h-[66px] items-center font-semibold text-white">
                Access to CRMs
              </div>
              <div className="flex h-[66px] items-center font-semibold text-white">
                Users
              </div>
              <div className="flex h-[66px] items-center font-semibold text-white">
                Setup
              </div>
              <div className="flex h-[66px] items-center font-semibold text-white">
                Consulting
              </div>
              <div className="flex h-[66px] items-center font-semibold text-white">
                Analytical Reports
              </div>
            </div>

            <div className="flex w-full rounded-3xl bg-white md:grid md:grid-cols-4">
              <div className="border-r border-gray-200">
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  1
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  100
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  <img src={Checkmark} alt="Checkmark" />
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700"></div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700"></div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700"></div>
                <div className="flex h-[66px] items-center justify-center font-extrabold text-primary-700"></div>
              </div>

              <div className="border-r border-gray-200">
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  Unlimited
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  1,000
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  <img src={Checkmark} alt="Checkmark" />
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  Unlimited
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700"></div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700"></div>
                <div className="flex h-[66px] items-center justify-center font-extrabold text-primary-700"></div>
              </div>

              <div className="border-r border-gray-200">
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  Unlimited
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  Unlimited
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  <img src={Checkmark} alt="Checkmark" />
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  Unlimited
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700"></div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700"></div>
                <div className="flex h-[66px] items-center justify-center font-extrabold text-primary-700"></div>
              </div>

              <div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  Unlimited
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  Unlimited
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  <img src={Checkmark} alt="Checkmark" />
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  Unlimited
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  <img src={Checkmark} alt="Checkmark" />
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  <img src={Checkmark} alt="Checkmark" />
                </div>
                <div className="flex h-[66px] items-center justify-center font-extrabold text-primary-700">
                  <img src={Checkmark} alt="Checkmark" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
